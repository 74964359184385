<template>
  <div>
    <v-card-title>Tworzenie dokumentu </v-card-title>
    <v-card-text>
      <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="submit">
        <!-- Ustawienia globalne w jednym rzędzie -->
        <v-container class="mt-6">
          <v-card-title>Ustawienia globalne</v-card-title>
          <v-row>
            <v-col cols="12" sm="3">
              <!-- Imię rolnika -->
              <v-text-field v-model="globalFarmerName" label="Imię rolnika"></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <!-- Nazwisko rolnika -->
              <v-text-field v-model="globalFarmerSurname" label="Nazwisko rolnika"></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <!-- Numer rolnika -->
              <v-text-field v-model="globalFarmerNumber" label="Numer rolnika"></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <!-- Rodzaj żywca -->
              <v-select
                  v-model="globalType"
                  :items="animalTypes"
                  label="Rodzaj żywca dla zaznaczonych sztuk"
              ></v-select>
            </v-col>
            <v-col cols="12">
              <!-- Zaznacz wszystkie sztuki -->
              <v-checkbox v-model="selectAllAnimals" label="Zaznacz wszystkie sztuki bydła" @change="toggleSelectAll"></v-checkbox>
            </v-col>
            <v-col cols="12">
              <!-- Przycisk zastosowania danych -->
              <v-btn @click="applyGlobalSettings">Zastosuj ustawienia</v-btn>
            </v-col>
          </v-row>
        </v-container>
        <v-container>
          <v-card-title>Specyfikacja transportu</v-card-title>
          <v-row>
            <v-col cols="12" sm="3">
              <v-text-field
                  v-model="formData.carrier_number"
                  label="Numer przewoźnika"
                  :rules="[rules.required, rules.maxLengthCarrier, rules.onlyDigits]"
                  maxlength="8"
                  required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-autocomplete
                  v-model="formData.herd_headquarters_number"
                  :items="herdHeadquartersNumbers"
                  item-title="herd_headquarters_number"
              item-value="herd_headquarters_number"
              label="Numer siedziby stada (dealera)"
              :rules="[rules.required]"
              required
              ></v-autocomplete>

            </v-col>
            <v-col cols="12" sm="6">
              <v-autocomplete
                  v-model="formData.recipient"
                  :items="recipients"
                  item-text="name"
                  prepend-icon="mdi-cow"
                  item-value="id"
                  label="Odbiorca"
                  :rules="[rules.required]"
                  required
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                  v-model="formData.place"
                  label="Miejscowość"
                  prepend-icon="mdi-map-marker"
                  :rules="[rules.required]"
                  required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                  v-model="formData.transport_time"
                  label="Przewidywany czas transportu (godziny)"
                  prepend-icon="mdi-clock-fast"
                  type="number"
                  :rules="[rules.required, rules.numberRequired, rules.numberPositive]"
                  required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                  v-model="formattedDateOfDeparture"
                  label="Data wyjazdu"
                  prepend-icon="mdi-calendar"
                  readonly
                  @click="departureDateMenu = true"
              ></v-text-field>
              <v-dialog
                  v-model="departureDateMenu"
                  max-width="290"
                  class="custom-dialog"
              >
                <v-date-picker
                    v-model="formData.date_of_departure"
                    @change="setDateOfDeparture"
                    :rules="[rules.required]"
                    color="primary"
                ></v-date-picker>
              </v-dialog>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                  v-model="formData.time_of_departure"
                  label="Godzina wyjazdu"
                  prepend-icon="mdi-clock"
                  readonly
                  @click="departureTimeMenu = true"
              ></v-text-field>
              <v-dialog
                  v-model="departureTimeMenu"
                  max-width="290"
                  class="custom-dialog"
              >
                <v-time-picker
                    v-model="formData.time_of_departure"
                    format="24hr"
                    @change="departureTimeMenu = false"
                    color="primary"
                    :rules="[rules.required]"
                ></v-time-picker>
              </v-dialog>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                  v-model="formData.truck_registration_number"
                  label="Numer rejestracyjny ciągnika siodłowego/ciężarówki"
                  prepend-icon="mdi-truck"
                  :rules="[rules.required, rules.maxLength]"
                  required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                  v-model="formData.trailer_registration_number"
                  prepend-icon="mdi-truck-plus"
                  label="Numer rejestracyjny naczepy"
                  :rules="[rules.maxLength]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-card-title>Dodaj sztuki</v-card-title>
              <v-row
                  v-for="(animal, index) in formData.animals"
                  :key="index"
                  align="center"
              >
                <v-col cols="auto">
                  <!-- Checkbox dla każdej sztuki -->
                  <v-checkbox
                      v-model="animal.selected"
                      @change="updateSelectAllStatus"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="auto">
                  <p class="font-weight-bold">
                    <span>{{ index + 1 }}</span>
                  </p>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-text-field
                      v-model="animal.farmer_name"
                      label="Imię rolnika"
                      :rules="[rules.required]"
                      required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-text-field
                      v-model="animal.farmer_surname"
                      label="Nazwisko rolnika"
                      :rules="[rules.required]"
                      required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-text-field
                      v-model="animal.farmer_registered_number"
                      label="Numer siedziby stada rolnika"
                      :rules="[rules.required]"
                      required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-text-field
                      v-model="formattedDateOfBirth[index]"
                  label="Data urodzenia"
                  prepend-icon="mdi-calendar"
                  @click:prepend="openBirthMenu(index)"
                  @blur="onDateBlur(index)"
                  placeholder="DD.MM.RRRR"
                  :rules="[rules.required, rules.validDate]"
                  ></v-text-field>

                  <v-dialog
                      v-model="formData.animals[index].birthMenu"
                      max-width="290"
                      class="custom-dialog"
                  >
                    <v-date-picker
                        v-model="formData.animals[index].date_of_birth"
                        @change="setDateOfBirth(index)"
                        color="primary"
                    ></v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-text-field
                      v-model="animal.earring_number"
                      label="Numer kolczyka"
                      :rules="[rules.required]"
                      required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="1">
                  <v-select
                      v-model="animal.type"
                      :items="animalTypes"
                      label="Rodzaj żywca"
                      :rules="[rules.required]"
                      required
                  ></v-select>
                </v-col>
                <v-col cols="1">
                  <v-btn icon @click="removeAnimal(index)" v-if="index > 0">
                    <v-icon color="red">mdi-delete</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-container>
            <v-btn @click="addAnimal">Dodaj Sztukę</v-btn>
          </v-container>
          <v-btn type="submit" :disabled="!valid" color="primary">Generuj Dokument</v-btn>
        </v-container>
      </v-form>
    </v-card-text>
    <v-dialog v-model="dialog" max-width="400">
      <v-card>
        <v-card-title class="headline">Sukces</v-card-title>
        <v-divider></v-divider>
        <div class="py-12 text-center">
          <v-icon class="mb-6" color="success" icon="mdi-check-circle-outline" size="200"></v-icon></div>
        <v-card-text class="py-6 text-center">Dokument został wygenerowany pomyślnie!</v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="green darken-1" text @click="closeDialog">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="errorDialog" max-width="400">
      <v-card>
        <v-card-title class="headline">Błąd</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="py-6 text-center">{{ errorMessage }}</v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="red darken-1" text @click="closeErrorDialog">Zamknij</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios';
import { format } from 'date-fns';
import { pl } from 'date-fns/locale';

export default {
  name: 'CreateDocument',
  data() {
    return {
      globalFarmerName: '',
      globalFarmerSurname: '',
      globalFarmerNumber: '',
      globalType: '', // Typ do ustawienia globalnego
      animalTypes: ['Buhaj', 'Jałowica', 'Krowa'],
      selectAllAnimals: false, // Zmienna dla checkboxa "Zaznacz wszystkie"
      valid: false,
      departureDateMenu: false,
      departureTimeMenu: false,
      species: ['BYDŁO'],
      recipients: [],
      herdHeadquartersNumbers: [],
      dialog: false,
      errorDialog: false,
      errorMessage: '',
      invalidDates: [], // Array to keep track of invalid dates for each animal
      formData: {
        species: 'BYDŁO',
        carrier_number: '',
        herd_headquarters_number: '',
        recipient: '',
        place: '',
        date_of_departure: new Date(),
        time_of_departure: this.formatTime(new Date()),
        transport_time: '',
        truck_registration_number: '',
        trailer_registration_number: '',
        animals: [
          {
            farmer_name: '', // New field
            farmer_surname: '', // New field
            farmer_registered_number: '',
            date_of_birth: null,
            earring_number: '',
            type: '',
            birthMenu: false,
            selected: false
          }
        ]
      },

      rules: {
        required: value => !!value || 'To pole jest wymagane.',
        validDate: value => /^\d{2}\.\d{2}\.\d{4}$/.test(value) || 'Wprowadź datę w formacie DD.MM.RRRR',
        numberRequired: value => !!value || 'To pole jest wymagane.',
        numberPositive: value => (value > 0) || 'Wartość musi być większa od 0.',
        maxLength: value => !value || value.length <= 8 || 'Numer rejestracyjny może mieć maksymalnie 8 znaków.',
        maxLengthCarrier: value => (value && value.length <= 8) || 'Numer przewoźnika może mieć maksymalnie 8 cyfr.',
        onlyDigits: value => /^\d*$/.test(value) || 'Numer przewoźnika może zawierać tylko cyfry.',
      },


    };
  },
  computed: {
    formattedDateOfDeparture() {
      return this.formData.date_of_departure
          ? format(new Date(this.formData.date_of_departure), 'dd.MM.yyyy', { locale: pl })
          : '';
    },
    formattedDateOfBirth() {
      return this.formData.animals.map((animal) =>
          animal.date_of_birth
              ? format(new Date(animal.date_of_birth), 'dd.MM.yyyy', { locale: pl })
              : ''
      );
    }
  },
  watch: {
    'formData.animals': {
      handler(newAnimals) {
        newAnimals.forEach((animal, index) => {
          if (animal.type !== 'Buhaj') {
            this.invalidDates[index] = false; // Resetuj walidację dla innych typów
            console.log('Reset walidacji dla:', animal.type, 'Index:', index);
          }
        });
      },
      deep: true
    },
    globalType(newVal) {
      // Nowa wartość globalType jest przypisywana, nie jest potrzebne wywołanie funkcji
      console.log("Wybrano nowy typ:", newVal);
    },
    selectAllAnimals(newVal) {
      // Jeśli `selectAllAnimals` jest zaznaczone, zaznaczamy wszystkie sztuki
      if (newVal) {
        this.formData.animals.forEach((animal) => (animal.selected = true));
      }
    }
  },
  methods: {
    toggleSelectAll() {
      // Ustawia zaznaczenie `selected` dla każdej sztuki zgodnie ze stanem `selectAllAnimals`
      this.formData.animals.forEach((animal) => (animal.selected = this.selectAllAnimals));
    },
    updateSelectAllStatus() {
      // Sprawdzamy, czy wszystkie sztuki są zaznaczone
      const allSelected = this.formData.animals.every((animal) => animal.selected);

      // Aktualizujemy `selectAllAnimals` tylko w zależności od zaznaczenia wszystkich sztuk, bez ich modyfikacji
      this.selectAllAnimals = allSelected;
    },
    applyGlobalSettings() {
      this.formData.animals.forEach((animal) => {
        if (animal.selected) {
          if (this.globalFarmerName) animal.farmer_name = this.globalFarmerName;
          if (this.globalFarmerSurname) animal.farmer_surname = this.globalFarmerSurname;
          if (this.globalFarmerNumber) animal.farmer_registered_number = this.globalFarmerNumber;
          if (this.globalType) animal.type = this.globalType;
        }
      });
    },
    async fetchHerdHeadquartersNumbers() {
      try {
        const token = localStorage.getItem('token');
        const user = JSON.parse(localStorage.getItem('user'));

        if (!token || !user) {
          throw new Error('User not authenticated');
        }

        const response = await axios.get(`https://app.aahjjwiumf.cfolks.pl/api/herd_headquarters_numbers?user_id=${user.id}`, {
          headers: { Authorization: `${token}` },
        });

        // Logowanie odpowiedzi z serwera
        console.log('Response data:', response.data);

        // Upewnij się, że przypisujesz dane poprawnie
        this.herdHeadquartersNumbers = response.data.map((item) => ({
          id: item.id,
          herd_headquarters_number: item.herd_headquarters_number.toString(), // Zapewnia, że jest to string
        }));

        console.log('Processed herdHeadquartersNumbers:', this.herdHeadquartersNumbers);

      } catch (error) {
        console.error('Błąd podczas pobierania numerów siedziby stada:', error);
        this.errorMessage = 'Wystąpił błąd podczas pobierania numerów siedziby stada.';
        this.errorDialog = true;
      }
    },
    async fetchUserCarrierNumber() {
      try {
        const token = localStorage.getItem('token');
        const user = JSON.parse(localStorage.getItem('user'));

        if (!token || !user) {
          throw new Error('User not authenticated');
        }

        // Pobierz numer przewoźnika dla zalogowanego użytkownika
        const response = await axios.get(`https://app.aahjjwiumf.cfolks.pl/api/users/${user.id}`, {
          headers: { Authorization: `${token}` }
        });

        // Przypisz numer przewoźnika do formularza (bez prefiksu WNIPL)
        this.formData.carrier_number = response.data.carrier_number || '';

      } catch (error) {
        console.error('Błąd podczas pobierania numeru przewoźnika użytkownika:', error);
        this.errorMessage = 'Wystąpił błąd podczas pobierania numeru przewoźnika.';
        this.errorDialog = true;
      }
    },
    formatDate(date) {
      return date ? format(new Date(date), 'dd.MM.yyyy', { locale: pl }) : '';
    },
    addAnimal() {
      this.formData.animals.push({
        farmer_name: '',
        farmer_surname: '',
        farmer_registered_number: '',
        date_of_birth: null,
        earring_number: '',
        type: this.selectAllAnimals ? this.globalType : '',
        birthMenu: false,
        selected: this.selectAllAnimals
      });
      this.invalidDates.push(false); // Add corresponding invalid date state
    },
    removeAnimal(index) {
      this.formData.animals.splice(index, 1);
      this.invalidDates.splice(index, 1); // Remove corresponding invalid date state
      this.updateSelectAllStatus();
    },
    setDateOfDeparture(date) {
      this.formData.date_of_departure = date;
      this.departureDateMenu = false;
    },
    setDateOfBirth(index, date) {
      console.log('Ustawianie daty urodzenia:', date);
      console.log('Typ zwierzęcia:', this.formData.animals[index].type);

      this.formData.animals[index].date_of_birth = date;
      this.formData.animals[index].birthMenu = false;
      this.updateFormattedDateOfBirth(index);

      const animalType = this.formData.animals[index].type;
      if (animalType === 'Buhaj') {
        const dateLimit = this.calculateDateLimit('Buhaj');
        this.invalidDates[index] = new Date(date) < dateLimit;
        console.log('Walidacja Buhaj: Data niepoprawna:', this.invalidDates[index]);
      } else {
        this.invalidDates[index] = false;
        console.log('Reset walidacji dla typu:', animalType);
      }
    },
    updateFormattedDateOfBirth(index) {
      // Aktualizuj sformatowaną datę po wyborze z kalendarza
      this.formattedDateOfBirth[index] = format(new Date(this.formData.animals[index].date_of_birth), 'dd.MM.yyyy', { locale: pl });
    },
    openBirthMenu(index) {
      this.formData.animals[index].birthMenu = true;  // Otwórz kalendarz po kliknięciu w ikonę
    },
    closeBirthMenu(index) {
      this.formData.animals[index].birthMenu = false;
    },
    onDateBlur(index) {
      // Sprawdź poprawność ręcznie wpisanej daty
      const rawDate = this.formattedDateOfBirth[index];
      const isValidDate = this.validateManualDate(rawDate);

      if (isValidDate) {
        const [day, month, year] = rawDate.split('.');
        const parsedDate = new Date(`${year}-${month}-${day}`);

        if (!isNaN(parsedDate)) {
          // Zaktualizuj model surowej daty, jeśli format jest poprawny
          this.formData.animals[index].date_of_birth = parsedDate;
        }
      } else {
        // Jeśli data nie jest poprawna, przywróć poprzednią wartość
        this.formattedDateOfBirth[index] = format(new Date(this.formData.animals[index].date_of_birth), 'dd.MM.yyyy', { locale: pl });
      }
    },
    validateManualDate(dateString) {
      // Sprawdź, czy data wpisana ręcznie jest poprawna
      const regex = /^\d{2}\.\d{2}\.\d{4}$/;  // Sprawdzanie formatu DD.MM.RRRR
      return regex.test(dateString);
    },
    formatTime(date) {
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      return `${hours}:${minutes}`;
    },
    getAnimalCount() {
      return this.formData.animals.length;
    },
    validateDateOfBirth(value, type) {
      console.log('Typ zwierzęcia:', type);
      console.log('Data urodzenia:', value);

      // Jeśli typ nie jest "Buhaj", pomijamy walidację i zwracamy true
      if (type !== 'Buhaj') {
        console.log('Pomijamy walidację dla typu:', type);
        return true;
      }

      // Dla "Buhaj" wykonujemy sprawdzenie daty
      const dateLimit = this.calculateDateLimit('Buhaj');
      const isValid = new Date(value) >= dateLimit;
      console.log('Walidacja dla Buhaj: Data', value, 'Limit:', dateLimit, 'Czy poprawna:', isValid);

      return isValid || 'Data urodzenia samca nie może być starsza niż 30 miesięcy od dnia bieżącego.';
    },
    calculateDateLimit(animalType) {
      const currentDate = new Date();
      if (animalType === 'Buhaj') {
        currentDate.setMonth(currentDate.getMonth() - 30);
      }
      return currentDate;
    },
    async submit() {
      if (this.$refs.form && this.$refs.form.validate()) {
        // Waliduj wszystkie daty przed wysłaniem
        const allDatesValid = this.formData.animals.every((animal, index) => {
          const dateLimit = this.calculateDateLimit(animal.type);
          const isValid = animal.type !== 'Buhaj' || new Date(animal.date_of_birth) >= dateLimit;
          this.invalidDates[index] = !isValid;
          return isValid;
        });

        if (!allDatesValid) {
          this.errorMessage = 'Niektóre daty są nieprawidłowe. Proszę poprawić błędy i spróbować ponownie.';
          this.errorDialog = true;
          return;
        }

        try {
          const user = JSON.parse(localStorage.getItem('user'));
          const token = localStorage.getItem('token');

          if (user && user.id) {
            const animalCount = this.getAnimalCount();

            // Send data to backend to save document and farmer
            const response = await axios.post(
                'https://app.aahjjwiumf.cfolks.pl/api/documents',
                {
                  user_id: user.id,
                  type: this.formData.species,
                  place: this.formData.place,
                  date_of_departure: this.formData.date_of_departure.toISOString().substr(0, 10),
                  time_of_departure: this.formData.time_of_departure,
                  transport_time: this.formData.transport_time,
                  truck_registration_number: this.formData.truck_registration_number,
                  trailer_registration_number: this.formData.trailer_registration_number,
                  recipient: this.formData.recipient,
                  selected_carrier_number: this.formData.carrier_number,
                  selected_herd_headquarters_number: this.formData.herd_headquarters_number,
                  animals: this.formData.animals.map(animal => ({
                    farmer_name: animal.farmer_name,
                    farmer_surname: animal.farmer_surname,
                    farmer_registered_number: animal.farmer_registered_number,
                    date_of_birth: animal.date_of_birth,
                    earring_number: animal.earring_number,
                    type: animal.type
                  })),
                  count: animalCount
                },
                {
                  headers: { Authorization: `${token}` }
                }
            );

            if (response.status === 200) {
              this.dialog = true;
            } else {
              throw new Error('Serwer zwrócił błąd');
            }
          }
        } catch (error) {
          console.error('Błąd podczas zapisywania dokumentu:', error.response?.data || error.message);
          this.errorMessage = error.response?.data?.message || 'Wystąpił błąd podczas zapisywania dokumentu. Dokument nie został zapisany.';
          this.errorDialog = true;
        }
      } else {
        this.errorMessage = 'Formularz nie jest poprawnie wypełniony.';
        this.errorDialog = true;
      }
    },
    closeDialog() {
      this.dialog = false;
      this.resetForm(); // Resetuje formularz po zamknięciu dialogu
      if (this.$refs.form) {
        this.$refs.form.reset(); // Resetuje formularz w kontekście Vuetify
      }
      window.location.reload(); // Automatyczne odświeżenie strony po zamknięciu dialogu
    },
    closeErrorDialog() {
      this.errorDialog = false;
    },
    resetForm() {
      this.formData = {
        species: 'BYDŁO',
        carrier_number: '',
        herd_headquarters_number: '',
        recipient: '',
        place: '',
        date_of_departure: new Date(),
        time_of_departure: this.formatTime(new Date()),
        transport_time: '',
        truck_registration_number: '',
        trailer_registration_number: '',
        animals: [
          {
            farmer_name: '',
            farmer_surname: '',
            farmer_registered_number: '',
            date_of_birth: null,
            earring_number: '',
            type: '',
            birthMenu: false
          }
        ]
      };
      this.invalidDates = [];  // Resetowanie walidacji dat
    },
    async fetchUserDetails() {
      try {
        const token = localStorage.getItem('token');
        const user = JSON.parse(localStorage.getItem('user'));

        if (!token || !user) {
          throw new Error('User not authenticated');
        }

        console.log('Fetching user details for user:', user);

        const recipientResponse = await axios.get('https://app.aahjjwiumf.cfolks.pl/api/recipient', {
          headers: { Authorization: `${token}` },
        });

        this.recipients = recipientResponse.data.map((recipient) => recipient.name);

        const userResponse = await axios.get(`https://app.aahjjwiumf.cfolks.pl/api/users/${user.id}`, {
          headers: { Authorization: `${token}` },
        });

        console.log('User response:', userResponse.data);

        if (userResponse.data.place) {
          this.formData.place = userResponse.data.place;
        } else {
          console.warn('No place found for user:', user.id);
        }

      } catch (error) {
        console.error('Błąd podczas pobierania danych:', error);
        this.errorMessage = 'Wystąpił błąd podczas pobierania danych użytkownika.';
        this.errorDialog = true;
      }
    }
  },
  mounted() {
    this.fetchUserDetails();
    this.fetchUserCarrierNumber();
    this.fetchHerdHeadquartersNumbers().then(() => {
      console.log('Loaded herdHeadquartersNumbers:', this.herdHeadquartersNumbers);
    });
  }
};
</script>



<style scoped>
.error {
  border: 2px solid red;
}
.custom-dialog .v-dialog__content {
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}

.v-icon {
  cursor: pointer;
  margin: 0 5px;
}
</style>


